import * as React from 'react'
import { useLocation } from 'react-router-dom'
import { LoudspeakerWhiteIcon } from 'src/assets/icons/customIcons/LoudspeakerWhite'
import { useAuth } from 'src/hooks/auth/authObservableValue'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { useUserState } from 'src/hooks/userState'

const limitedFunctionalityPages = [
  'student/billing/balance',
  'student/billing/schedule',
  'student/billing/deposits',
  'student/billing/instruction',
  'notifications',
  'library',
]

export default function RegimeMessageAlert(): React.ReactElement | null {
  const userState = useUserState()
  const t = useTranslatable()
  const auth = useAuth()
  const { pathname } = useLocation()
  const locale = useLocale()

  const isLimitedFunctionalityPage =
    limitedFunctionalityPages.some((page) => pathname === `/${locale}/${page}`) ||
    pathname.startsWith(`/${locale}/news`)

  if (auth.state === 'Authenticated' && (userState.data?.regime?.functionalIsLimited ?? false)) {
    return (
      <>
        {isLimitedFunctionalityPage && (
          <div className="mb-[30px] flex items-center justify-center rounded-card bg-lightRed px-4 py-3 print:hidden">
            <div className="flex min-h-[48px] min-w-[48px] items-center justify-center rounded-full bg-primaryRed">
              <LoudspeakerWhiteIcon />
            </div>
            <span className="mx-6 w-full text-primaryRed dark:text-white">{t('common:page_is_disabled')}</span>
          </div>
        )}
        {userState.data?.regime?.message != null ? (
          <div className="mb-[30px] flex items-center justify-center rounded-card bg-[#ef9d0fdc] px-4 py-3 print:hidden">
            <div className="flex min-h-[48px] min-w-[48px] items-center justify-center rounded-full bg-[#EF9B0F]">
              <LoudspeakerWhiteIcon />
            </div>
            <span className="mx-6 w-full text-white">
              <div dangerouslySetInnerHTML={{ __html: userState.data?.regime?.message }} />
            </span>
          </div>
        ) : null}
      </>
    )
  }

  return null
}
