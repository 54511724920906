import * as ProSolidSvgIcons from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { Navigate, useSearchParams } from 'react-router-dom'
import { useTranslatable } from 'src/hooks/locale/utils'

export default function UrlRedirectPage(): React.ReactElement | null {
  const t = useTranslatable()

  const [searchParams] = useSearchParams()
  const url = parseURL(searchParams.get('go'))

  React.useEffect(() => {
    if (!url) return

    window.location.href = url
  }, [url])

  if (!url) {
    return <Navigate to="/" />
  }

  return (
    <>
      <Helmet title={t('system:you_are_being_redirected')} />

      <div className="rounded border px-3 py-5 text-center">
        <h1 className="mb-4">
          <FontAwesomeIcon icon={ProSolidSvgIcons.faArrowRightToLine} className="text-lightSecondaryWarning" />{' '}
          {t('system:you_are_being_redirected')}
        </h1>
        <p className="mb-4">{t('system:you_will_be_redirected_to_the_following_url')}</p>
        <p className="mb-4">{url}</p>
      </div>
    </>
  )
}

// Allowed protocols for redirection (http and https)
// This is to prevent redirects to JavaScript URIs (e.g., "javascript:alert()")
const allowedProtocols = ['http:', 'https:']

function parseURL(url?: string | null): string | null {
  if (!url) return null

  try {
    const destination = new URL(url)

    if (!allowedProtocols.includes(destination.protocol)) {
      console.error(`Invalid URL protocol: ${destination.protocol}`)
      return null
    }

    return destination.href
  } catch (error) {
    console.error('Invalid URL provided for redirection:', error)
    return null
  }
}
