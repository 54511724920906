import * as React from 'react'
import { Link } from 'react-router-dom'
import env from 'src/helpers/env'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'

export default function Footer(): React.ReactElement | null {
  const t = useTranslatable()
  const locale = useLocale()

  const hash = env.REVISION.slice(0, 8)
  const oldArgusLocale = locale === 'ka' ? 'ge' : 'en'
  const oldArgusVersion =
    env.APP_ENV === 'PRODUCTION'
      ? `https://argus-old.iliauni.edu.ge/${oldArgusLocale}`
      : `https://staging-argus-old.iliauni.edu.ge/${oldArgusLocale}`
  return (
    <footer className="bottom-0 left-0 z-10 mt-auto flex w-full flex-wrap justify-between border-t border-borderGrey bg-primaryWhite py-5 print:hidden">
      <div className="m-2">
        <span className="text-primaryTextColor">v5.0.0-{hash}</span>
      </div>
      <div className="m-2 text-primaryTextColor">
        {`© ${t('system:argus')} - `}
        <a href={`http://iliauni.edu.ge/${locale}`} target="_blank" rel="noreferrer noopener">
          {t('system:ilia_state_university')}
        </a>
      </div>
      <div className="m-2 text-primaryTextColor">
        <div className="mb-2">
          <Link to={`/${locale}/faq`}>{t('faq:title')}</Link>
        </div>
        <div className="mb-2">
          <a href={`https://iliauni.edu.ge/privacy-policy`} target="_blank" rel="noreferrer">
            {t('system:privacy_policy')}
          </a>
        </div>
        <div className="mb-2">
          <a href={`https://iliauni.edu.ge/cookie-files`} target="_blank" rel="noreferrer">
            {t('system:cookies_policy')}
          </a>
        </div>
        <div className="mb-2">
          <Link to="application">{t('uncategorized:mobile_application')}</Link>
        </div>
        <div className="mb-2">
          <a href={oldArgusVersion} target="_blank" rel="noreferrer">
            {t('uncategorized:old_argus_version')}
          </a>
        </div>
      </div>
    </footer>
  )
}
